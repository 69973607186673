import React from 'react';
// STYLE
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
// ICONS
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PrintIcon from '@material-ui/icons/Print';
import MultiPrint from './MultiPrint';
import { navigate } from 'gatsby';
import { useSnackbar } from 'notistack';
import { useReactiveVar } from '@apollo/client';
import { bibSearchResultVar } from '../../../apollo/localState/mainSearch/bibSearchResultState';

const CheckedOperations = props => {
  const bibState = useReactiveVar(bibSearchResultVar);
  const { enqueueSnackbar } = useSnackbar();

  const handleMultiPrint = () => {
    if (bibState.selectedRecords.length === 0) {
      enqueueSnackbar('Sikertelen művelet! Jelöljön ki rekordokat!', {
        variant: 'error',
      });
    } else navigate('/multiprint/print');
  };

  const handleMultiMail = () => {
    if (bibState.selectedRecords.length === 0) {
      enqueueSnackbar('Sikertelen művelet! Jelöljön ki rekordokat!', {
        variant: 'error',
      });
    } else navigate('/multiprint/mailto');
  };

  const handleExport = () => {
    if (bibState.selectedRecords.length === 0) {
      enqueueSnackbar('Sikertelen művelet! Jelöljön ki rekordokat!', {
        variant: 'error',
      });
    } else navigate('/multiprint/export');
  };

  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      alignItems="center"
      style={{ margin: '.5rem 0' }}
      className="operationsContainer"
    >
      <Grid item>A kijelölt találatokkal kapcsolatos műveletek:</Grid>
      <Grid item>
        <IconButton size="small" onClick={handleExport}>
          <Grid container alignItems="center">
            <Grid item>
              <VerticalAlignTopIcon />
            </Grid>
            <Grid item>Export</Grid>
          </Grid>
        </IconButton>
      </Grid>
      {/** @todo gomb visszarakása ha működik a funkció */}
      {/* <Grid item>
        <IconButton size="small" onClick={handleMultiMail}>
          <Grid container alignItems="center">
            <Grid item>
              <MailOutlineIcon />
            </Grid>
            <Grid item>E-mail</Grid>
          </Grid>
        </IconButton>
      </Grid> */}
      <Grid item>
        <IconButton size="small" onClick={handleMultiPrint}>
          <Grid container alignItems="center">
            <Grid item>
              <PrintIcon />
            </Grid>
            <Grid item>Nyomtatás</Grid>
          </Grid>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CheckedOperations;
