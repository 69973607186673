import React, { useState, useEffect } from 'react';
//STYLE
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// API
import {
  ME_QUERY,
  SEARCH_RECORDS,
  SEARCH_SIMPLE_RECORDS,
} from '../apollo/queries/searchRelatedQueries';
// HOOKS
import { useQueryParams } from 'use-query-params';
import {
  useLazyQuery,
  useApolloClient,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useBibSearchResultStateSetters } from '../hooks/useBibSearchResultStateSetters';
// UTILS
import {
  onSearch,
  onSearchQueryCompleted,
} from '../components/MainSearch/utils';
// CONSTANTS
import MainSearch, {
  MAIN_SEARCH_URL_STATE_DEFAULT_VALUE,
  searchQueryDefaultValue,
} from '../components/MainSearch/MainSearch';
// COMPONENTS
import SubPageLayout from '../layouts/subpage_layout';
import ElementList from '../components/ElementList/ElementList';
import { userInfoVar } from '../apollo/localState/userInfoVar';
import Head from '../components/head';
import { accessTokenVar } from '../apollo/localState/accessTokenVar';

const RecordList = props => {
  const apolloClient = useApolloClient();
  const {
    setBibSearchResultStateProperties,
  } = useBibSearchResultStateSetters();
  const [defaultUrlState] = useQueryParams(MAIN_SEARCH_URL_STATE_DEFAULT_VALUE);

  const { accessToken } = useReactiveVar(accessTokenVar);
  const { isMemberCardActive } = useReactiveVar(userInfoVar);

  const [urlState, setUrlState] = useState({
    tabValue: 0,
    itemPerPage: 10,
    view: 'list',
    sort: 'title_ASC',
    searchField: null,
    searchValue: null,
    searchQuery: searchQueryDefaultValue,
  }); //FIRST VALUE SET IN USEEFFECT

  const [searchRecordsSimple] = useLazyQuery(SEARCH_SIMPLE_RECORDS, {
    onCompleted: data => {
      onSearchQueryCompleted(
        data?.simpleSearchBibliographicsRecords_v2,
        apolloClient,
        setBibSearchResultStateProperties
      );
    },
  });

  const [searchRecordsComplex] = useLazyQuery(SEARCH_RECORDS, {
    onCompleted: data => {
      onSearchQueryCompleted(
        data?.complexSearchBibliographicsRecords_v2,
        apolloClient,
        setBibSearchResultStateProperties
      );
    },
  });

  const { data: meData } = useQuery(ME_QUERY, {
    skip: !accessToken,
  });

  useEffect(() => {
    const parsedDefaultUrlState = {
      tabValue: defaultUrlState?.tabValue ? +defaultUrlState.tabValue : 0,
      itemPerPage: defaultUrlState?.itemPerPage || 10,
      view: defaultUrlState.view ? defaultUrlState.view : 'list',
      sort: defaultUrlState.sort ? defaultUrlState.sort : 'title_ASC',
      searchField:
        props?.location?.state?.searchField ||
        defaultUrlState.searchField ||
        null,
      searchValue:
        props?.location?.state?.searchValue ||
        defaultUrlState.searchValue ||
        null,
      searchQuery: defaultUrlState.searchQuery || searchQueryDefaultValue,
    };

    setUrlState(parsedDefaultUrlState);

    if (parsedDefaultUrlState.tabValue === 0) {
      if (!parsedDefaultUrlState.searchField) {
        return;
      }
      setBibSearchResultStateProperties({ loading: true });
      onSearch(parsedDefaultUrlState, searchRecordsSimple);
    }

    if (parsedDefaultUrlState.tabValue === 1) {
      setBibSearchResultStateProperties({ loading: true });
      onSearch(parsedDefaultUrlState, searchRecordsComplex);
    }
  }, []);

  const rootGridStyle = {
    marginTop: !isMemberCardActive ? 160 : 130,
  };

  return (
    <SubPageLayout>
      <Head title="Katalógus" />
      <Container maxWidth="xl">
        <Grid container direction="column" style={rootGridStyle} spacing={1}>
          <Grid item>
            <MainSearch
              urlState={urlState}
              setUrlState={setUrlState}
              searchClickedHandler={() => {
                const { tabValue } = urlState;
                onSearch(
                  urlState,
                  tabValue === 0 ? searchRecordsSimple : searchRecordsComplex
                );
              }}
            />
          </Grid>
          <Grid item>
            <ElementList
              onSearch={receivedUrlState => {
                if (receivedUrlState) {
                  const { tabValue } = receivedUrlState;
                  onSearch(
                    receivedUrlState,
                    tabValue === 0 ? searchRecordsSimple : searchRecordsComplex
                  );
                  return null;
                }

                const { tabValue } = urlState;
                onSearch(
                  urlState,
                  tabValue === 0 ? searchRecordsSimple : searchRecordsComplex
                );
              }}
              urlState={urlState}
              setUrlState={setUrlState}
              me={meData?.me}
            />
          </Grid>
        </Grid>
      </Container>
    </SubPageLayout>
  );
};

export default RecordList;
