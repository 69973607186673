import React from 'react';
import { bibSearchResultVar } from '../../../apollo/localState/mainSearch/bibSearchResultState';
// STYLE
import searchStyles from '../../../pages/search.module.scss';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Pagination from 'material-ui-flat-pagination';
// HOOKS
import { useReactiveVar } from '@apollo/client';
import { useBibSearchResultStateSetters } from '../../../hooks/useBibSearchResultStateSetters';

const ElementListPagination = ({ urlState, onSearch }) => {
  const {
    setBibSearchResultStateProperties,
  } = useBibSearchResultStateSetters();
  const bibState = useReactiveVar(bibSearchResultVar);
  const { recordCount, page } = bibState;
  const { itemPerPage } = urlState;

  return (
    <Grid item xs={12} className={searchStyles.paginationBox}>
      <Box className={searchStyles.paginationBoxInner}>
        <Box className={searchStyles.paginationBoxInnerContent}>
          <Pagination
            offset={page * itemPerPage}
            limit={itemPerPage}
            total={recordCount}
            onClick={(e, offset) => {
              setBibSearchResultStateProperties({
                page: offset / itemPerPage,
                loading: true,
              });
              onSearch();
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default ElementListPagination;
