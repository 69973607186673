import React, { useState } from 'react';
import { bibSearchResultVar } from '../../../apollo/localState/mainSearch/bibSearchResultState';
// STYLE
import searchStyles from '../../../pages/search.module.scss';
// HOOKS
import { useQuery, useReactiveVar } from '@apollo/client';
// UTILS
import { getSubfieldValue } from '../utils';
// COMPONENTS
import ListElement from '../../ListElement/ListElement';
import ViewMyReservationModal from './ViewMyReservationModal/ViewMyReservationModal';
import ViewMyRequestModal from './ViewMyRequestModal/ViewMyRequestModal';
import {
  getResolvedRecordsQuery,
} from '../../../apollo/queries/searchRelatedQueries';
import { getValueFromMarc } from '../../../utils/marc';
import { accessTokenVar } from '../../../apollo/localState/accessTokenVar';
import jwt_decode from 'jwt-decode';
import { CircularProgress, Grid } from '@material-ui/core';
import { controlledFeatures } from '../../../utils/featureControl';

const ElementListRecords = ({ allDoctypeMappingCsv, me }) => {
  const { accessToken } = useReactiveVar(accessTokenVar);
  const bibState = useReactiveVar(bibSearchResultVar);
  const {
    records,
    includedProfiles,
    includedIndexEntries,
    selectedRecords,
  } = bibState;

  const { data, loading } = useQuery(
    getResolvedRecordsQuery({fetchItems: controlledFeatures.itemList}),
    {
      variables: {
        where: {
          id_in: records.map(record => record.id),
        },
        ...(accessToken
          ? {
              calculatedItemPolicyForPatronWhere: {
                id: accessToken ? jwt_decode(accessToken).guid : '',
              },
            }
          : null),
        first: records?.length,
        skip: 0,
      },
      skip: records?.length === 0,
    }
  );

  const [reservationData, setReservationData] = useState(null);
  const [requestData, setRequestData] = useState(null);

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  const mappedRecordsByElasticResult = records.map(record =>
    data?.searchBibliographicRecord?.nodes?.find(x => x.guid === record.id)
  );

  return (
    <React.Fragment>
      {mappedRecordsByElasticResult?.map(record => {
        return (
          <li key={record?.guid} className={searchStyles.searchSingleItem}>
            <ListElement
              selectedRecords={selectedRecords}
              allDoctypeMappingCsv={allDoctypeMappingCsv}
              node={{
                guid: record?.guid,
                // type: record.type,
                // type: getSubfieldValue(
                //   '901',
                //   'b',
                //   record,
                //   includedProfiles,
                //   includedIndexEntries
                // ),
                type: getValueFromMarc('901', 'b', record?.rawMarcRecord),
                title:
                  getValueFromMarc('787', 'g', record?.rawMarcRecord) ||
                  getValueFromMarc('774', 'g', record?.rawMarcRecord) ||
                  getValueFromMarc('245', 'a', record?.rawMarcRecord),
                // title: getSubfieldValue(
                //   '245',
                //   'a',
                //   record,
                //   includedProfiles,
                //   includedIndexEntries
                // ),
                author:
                  getValueFromMarc('100', 'a', record?.rawMarcRecord) ||
                  getValueFromMarc('245', 'c', record?.rawMarcRecord),
                // author:
                //   getSubfieldValue(
                //     '100',
                //     'a',
                //     record,
                //     includedProfiles,
                //     includedIndexEntries
                //   ) ||
                //   getSubfieldValue(
                //     '245',
                //     'c',
                //     record,
                //     includedProfiles,
                //     includedIndexEntries
                //   ),
                placeOfAppearance: getValueFromMarc(
                  '260',
                  'a',
                  record?.rawMarcRecord
                ),
                // placeOfAppearance: getSubfieldValue(
                //   '260',
                //   'a',
                //   record,
                //   includedProfiles,
                //   includedIndexEntries
                // ),
                publisher: getValueFromMarc('260', 'b', record?.rawMarcRecord),
                // publisher: getSubfieldValue(
                //   '260',
                //   'b',
                //   record,
                //   includedProfiles,
                //   includedIndexEntries
                // ),
                releaseDate: getValueFromMarc(
                  '260',
                  'c',
                  record?.rawMarcRecord
                ),
                // releaseDate: getSubfieldValue(
                //   '260',
                //   'c',
                //   record,
                //   includedProfiles,
                //   includedIndexEntries
                // ),
                rawMarcRecord: record?.rawMarcRecord,
                items: record?.physicalManifestations?.nodes,
                itemCount: record?.physicalManifestations?.aggregate?.count,
                publicFiles: record?.publicFiles,
              }}
              me={me}
              setReservationData={setReservationData}
              setRequestData={setRequestData}
            />
          </li>
        );
      })}
      <ViewMyReservationModal
        reservationData={reservationData}
        handleClose={() => setReservationData(null)}
      />
      <ViewMyRequestModal
        requestData={requestData}
        handleClose={() => setRequestData(null)}
      />
    </React.Fragment>
  );
};

export default ElementListRecords;
