import React, { useMemo } from 'react';
import { Link } from 'gatsby';
// STYLE
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
// HOOKS
import { useBibSearchResultStateSetters } from '../../../../../hooks/useBibSearchResultStateSetters';
import { useReactiveVar } from '@apollo/client';
import { bibSearchResultVar } from '../../../../../apollo/localState/mainSearch/bibSearchResultState';
// UTILS
import { getSubfieldValue } from '../../../utils';
import { makeStyles } from '@material-ui/core';
import { getValueFromMarc } from '../../../../../utils/marc';

const useStyles = makeStyles(() => ({
  linkImageDiv: {
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
  linkImage: {
    maxHeight: '20%',
    maxWidth: '20%',
  },
  linkText: {
    textAlign: 'center',
    fontWeight: 'initial',
  },
}));

const ElementListMosaicViewRecordCardContent = ({
  record,
  allDoctypeMappingCsv,
}) => {
  const classes = useStyles();
  const { guid: id } = record;
  const {
    setBibSearchResultStateProperties,
  } = useBibSearchResultStateSetters();
  const bibState = useReactiveVar(bibSearchResultVar);
  const {
    selectedRecords,
    includedProfiles = [],
    includedIndexEntries = [],
  } = bibState;

  const matchCurrentElement = allDoctypeMappingCsv.nodes.find(
    node => node.hun === node.type
  )
    ? allDoctypeMappingCsv.nodes.find(node => node.hun === node.type)
    : allDoctypeMappingCsv.nodes.find(node => node.key === 'else');

  const publicationDataString = useMemo(() => {
    const publicationPlace = getValueFromMarc('260', 'a', record.rawMarcRecord);
    const publisher = getValueFromMarc('245', 'c', record.rawMarcRecord);
    const releaseDate = getValueFromMarc('260', 'b', record.rawMarcRecord);

    return [publicationPlace, publisher, releaseDate].filter(x => x).join(', ');
  }, [id]);

  return (
    <CardContent
      style={{
        height: 200,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        // whiteSpace: 'nowrap',
      }}
    >
      <div style={{ textAlign: 'right' }}>
        <Checkbox
          color="primary"
          value={selectedRecords.includes(id)}
          onChange={e => {
            if (e.target.checked) {
              setBibSearchResultStateProperties({
                selectedRecords: [...selectedRecords, id],
              });
              return null;
            }

            setBibSearchResultStateProperties({
              selectedRecords: selectedRecords.filter(
                selectedRecordId => id !== selectedRecordId
              ),
            });
          }}
        />
      </div>
      <Link to={'/elements/' + id}>
        <div className={classes.linkImageDiv}>
          <img
            src={matchCurrentElement.svg}
            alt={matchCurrentElement.hun}
            className={classes.linkImage}
          />
        </div>
        <div className={classes.linkText}>
          {/* <Typography> */}
          {getValueFromMarc('787', 'g', record?.rawMarcRecord) ||
            getValueFromMarc('774', 'g', record?.rawMarcRecord) ||
            getValueFromMarc('245', 'a', record?.rawMarcRecord)}
          {/* </Typography> */}
          {/* <Typography variant="body1"> */}

          {/* </Typography> */}
        </div>
        <div className={classes.linkText}>{publicationDataString}</div>
      </Link>
    </CardContent>
  );
};

export default ElementListMosaicViewRecordCardContent;
