import { bibSearchResultVar } from '../../apollo/localState/mainSearch/bibSearchResultState';
// API
import {
  GET_INDEX_ENTRIES,
  GET_PROFILES,
} from '../../apollo/queries/searchRelatedQueries';

export const onSearch = (urlState, searchRecords) => {
  const { tabValue } = urlState;
  if (tabValue === 0) {
    onSimpleSearch(urlState, searchRecords);
    return null;
  }

  if (tabValue === 1) {
    onComplexSearch(urlState, searchRecords);
    return null;
  }
};

const onSimpleSearch = (urlState, searchRecords) => {
  const { searchField, searchValue, itemPerPage, sort } = urlState;
  const bibResultState = bibSearchResultVar();

  if (!searchField) {
    bibSearchResultVar({
      loading: false,
    });
    return null;
  }

  const { page, filters } = bibResultState;

  const finalSearchValue =
    typeof searchValue?.value === 'object'
      ? JSON.stringify(searchValue.value)
      : searchValue?.value;

  const postData = {
    searchType: searchField.slug,
    searchValue: finalSearchValue,
    limit: itemPerPage,
    offset: itemPerPage * page,
    sortType: sort.split('_')[0],
    sortOrder: sort.split('_')[1],
  };

  if (filters && Object.keys(filters).length > 0) {
    postData.filters = filters;
  }

  searchRecords({
    variables: postData,
  });
};

const onComplexSearch = (urlState, searchRecords) => {
  const { searchQuery, itemPerPage, sort } = urlState;

  const bibResultState = bibSearchResultVar();
  const { page, filters } = bibResultState;

  const postData = {
    limit: itemPerPage,
    offset: itemPerPage * page,
    sortType: sort.split('_')[0],
    sortOrder: sort.split('_')[1],
    dedicatedQuery: [],
    searchQuery: searchQuery
      .filter(searchObject => searchObject.searchValue)
      .map(searchObject => {
        const { searchField, type, searchValue, operator } = searchObject;

        const postObject = {
          slug: searchField.slug,
          type,
          keyword: searchValue,
        };

        if (operator) {
          postObject.operator = operator.toUpperCase();
        }

        return postObject;
      }),
  };

  if (filters) {
    postData.filters = filters;
  }

  searchRecords({
    variables: postData,
  });
};

export const onSearchQueryCompleted = (
  res = {},
  apolloClient,
  setBibSearchResultStateProperties
) => {
  const { records, recordCount, count, aggregations } = res;
  const bibResultState = bibSearchResultVar();
  const { aggregations: stateAggregations } = bibResultState;

  setBibSearchResultStateProperties({
    records: records /* .map((record) => {
      return {
        ...record,
        record: {
          ...record.record,
          datafields: record.record.datafields.map((datafield) => ({
            ...datafield,
            subfields: datafield.subfields.map((subfield) => ({
              ...subfield,
              code: subfield.code,
              originalValue: subfield.value,
              value: subfield.value.value,
              unitOfValues: subfield.value.unitOfValues
                ? subfield.value.unitOfValues
                : [],
            })),
          })),
        },
      };
    }) */,
    recordCount,
    // includedProfiles: profiles,
    // includedIndexEntries: indexEntries,
    loading: false,
    aggregations:
      Object.keys(stateAggregations).length > 0
        ? stateAggregations
        : aggregations, //IGAZI KERESÉS INICIALIZÁLÁSA ELŐTT KIRESETELEM EZÉRT MŰKÖDHET ÍGY
  });
  return;
  const includedIndexEntryGuids = getIncludedIndexEntryGuids(nodes);

  const includedProfileIds = nodes.reduce((acc, record) => {
    const profileId = record.profileId;

    if (acc.includes(profileId)) {
      return acc;
    }

    return [...acc, profileId];
  }, []);

  apolloClient
    .query({
      query: GET_INDEX_ENTRIES,
      variables: {
        first: includedIndexEntryGuids.length,
        where: {
          guid_in: includedIndexEntryGuids,
        },
      },
    })
    .then(indexEntriesRes => {
      apolloClient
        .query({
          query: GET_PROFILES,
          variables: {
            first: includedProfileIds.length,
            where: {
              id_in: includedProfileIds,
            },
          },
        })
        .then(profileRes => {
          setResultStateAsPerResponse(
            res,
            indexEntriesRes.data.simpleSearchIndexEntries,
            profileRes.data.getProfilesByIds.nodes,
            setBibSearchResultStateProperties
          );
        });
    });
};

export const getIncludedIndexEntryGuids = records => {
  const includedIndexEntryGuids = [];

  for (let i = 0; i < records.length; i++) {
    const record = records[i].record;
    for (let k = 0; k < record.datafields.length; k++) {
      const recordDatafield = record.datafields[k];
      for (let l = 0; l < recordDatafield.subfields.length; l++) {
        const recordDatafieldSubfield = recordDatafield.subfields[l];
        if (recordDatafieldSubfield?.value?.namespace === 'indexEntry') {
          const indexEntryGuid = recordDatafieldSubfield.value.value;
          if (
            indexEntryGuid &&
            !includedIndexEntryGuids.includes(indexEntryGuid)
          ) {
            includedIndexEntryGuids.push(indexEntryGuid);
          }
        }
      }
    }
  }

  return includedIndexEntryGuids;
};

const setResultStateAsPerResponse = (
  res,
  indexEntries,
  profiles,
  setBibSearchResultStateProperties
) => {
  const { records, recordCount, aggregations } = res;
  const bibResultState = bibSearchResultVar();
  const { aggregations: stateAggregations } = bibResultState;
  setBibSearchResultStateProperties({
    records: records.map(record => {
      return {
        ...record,
        record: {
          ...record.record,
          datafields: record.record.datafields.map(datafield => ({
            ...datafield,
            subfields: datafield.subfields.map(subfield => ({
              ...subfield,
              code: subfield.code,
              originalValue: subfield.value,
              value: subfield.value.value,
              unitOfValues: subfield.value.unitOfValues
                ? subfield.value.unitOfValues
                : [],
            })),
          })),
        },
      };
    }),
    recordCount,
    includedProfiles: profiles,
    includedIndexEntries: indexEntries,
    loading: false,
    aggregations:
      Object.keys(stateAggregations).length > 0
        ? stateAggregations
        : aggregations, //IGAZI KERESÉS INICIALIZÁLÁSA ELŐTT KIRESETELEM EZÉRT MŰKÖDHET ÍGY
  });
};
