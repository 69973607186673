import React from 'react';
// STYLE
import searchStyles from '../../../../pages/search.module.scss';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// HOOKS
import { useQueryParams } from 'use-query-params';
// CONSTANTS
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../../../MainSearch/MainSearch';

const ElementListRecordCountRowViewSelect = ({ urlState, setUrlState }) => {
  const { view } = urlState;

  const [_, setDefaultUrlState] = useQueryParams(
    MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  );

  return (
    <FormControl variant="outlined" className={searchStyles.pagerControl}>
      <Select
        value={view}
        onChange={e => {
          const newValue = e.target.value;
          setDefaultUrlState({
            view: newValue,
          });
          setUrlState(prevState => {
            return {
              ...prevState,
              view: newValue,
            };
          });
        }}
      >
        <MenuItem value="list">Lista</MenuItem>
        <MenuItem value="mosaic">Mozaik</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ElementListRecordCountRowViewSelect;
