import React from 'react';
// STYLE
import searchStyles from '../../../../pages/search.module.scss';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// HOOKS
import { useQueryParams } from 'use-query-params';
// CONSTANTS
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../../../MainSearch/MainSearch';
import { useBibSearchResultStateSetters } from '../../../../hooks/useBibSearchResultStateSetters';

const SORT_OPTIONS = [
  {
    label: 'Cím növekvő',
    value: 'title_ASC',
  },
  {
    label: 'Cím csökkenő',
    value: 'title_DESC',
  },
  {
    label: 'Szerző növekvő',
    value: 'author_ASC',
  },
  {
    label: 'Szerző csökkenő',
    value: 'author_DESC',
  },
  {
    label: 'Kiadás éve növekvő',
    value: 'publish-year_ASC',
  },
  {
    label: 'Kiadás éve csökkenő',
    value: 'publish-year_DESC',
  },
];

const ElementListRecordCountRowSortSelect = ({
  urlState,
  setUrlState,
  onSearch,
}) => {
  const { sort } = urlState;
  const [_, setDefaultUrlState] = useQueryParams(
    MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  );
  const {
    setBibSearchResultStateProperties,
  } = useBibSearchResultStateSetters();

  return (
    <FormControl variant="outlined" className={searchStyles.pagerControl}>
      <Select
        value={sort}
        onChange={e => {
          setBibSearchResultStateProperties({
            loading: true,
          });
          const newValue = e.target.value;
          const newUrlState = {
            ...urlState,
            sort: newValue,
          };
          setDefaultUrlState({
            sort: newValue,
          });
          setUrlState(prevState => {
            return {
              ...prevState,
              sort: newValue,
            };
          });
          onSearch(newUrlState);
        }}
      >
        {SORT_OPTIONS.map(option => {
          const { label, value } = option;
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ElementListRecordCountRowSortSelect;
