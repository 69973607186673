import React, { useState } from 'react';
import { bibSearchResultVar } from '../../apollo/localState/mainSearch/bibSearchResultState';
import { useStaticQuery, graphql } from 'gatsby';
// STYLE
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import styles from '../../pages/index.module.scss';
import searchStyles from '../../pages/search.module.scss';
import '../../styles/basic_style.scss';
// HOOKS
import { useReactiveVar } from '@apollo/client';
// COMPONENTS
import ElementListRecords from './ElementListRecords/ElementListRecords';
import ElementListRecordCountRow from './ElementListRecordCountRow/ElementListRecordCountRow';
import MainSearchBibResultFilter from '../RecordListContent/MainSearchBibResultFilter/MainSearchBibResultFilter';
import ElementListPagination from './ElementListPagination/ElementListPagination';
import ErrorSnackbar from '../../components/ErrorSnackbar/ErrorSnackbar';
import CheckedOperations from './CheckedOperations/CheckedOperations';
import ElementListMosaicViewRecords from './ElementListMosaicViewRecords/ElementListMosaicViewRecords';

const DOC_TYPE_QUERY = graphql`
  query GetDoctypeData {
    allDoctypeMappingCsv {
      nodes {
        hun
        key
        svg
        eng
      }
    }
  }
`;

const ElementList = props => {
  const { onSearch, urlState, setUrlState, me } = props;
  const bibState = useReactiveVar(bibSearchResultVar);
  const { loading } = bibState;
  const docTypeData = useStaticQuery(DOC_TYPE_QUERY);
  const { allDoctypeMappingCsv } = docTypeData;

  const [errorModal, setErrorModal] = useState({
    open: false,
    errorMessage: null,
  });

  const closeErrorModalHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorModal({ ...errorModal, open: false });
  };

  return (
    <React.Fragment>
      <Grid
        className={styles.mainContainer}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Box
          className={styles.pageContentContainerSubpageWhite}
          component="section"
        >
          <Container maxWidth="lg">
            <ElementListRecordCountRow
              urlState={urlState}
              setUrlState={setUrlState}
              onSearch={onSearch}
            />
            <Grid container>
              <MainSearchBibResultFilter onSearch={onSearch} />
              {loading ? (
                <div style={{ margin: '0 auto' }}>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <React.Fragment>
                  <Grid item md={9} className={searchStyles.searchResultsList}>
                    <Box className={searchStyles.searchResultsItems}>
                      {urlState.view === 'mosaic' ? (
                        <ElementListMosaicViewRecords
                          allDoctypeMappingCsv={allDoctypeMappingCsv}
                        />
                      ) : (
                        <React.Fragment>
                          <ol></ol>
                          <ol>
                            <ElementListRecords
                              allDoctypeMappingCsv={allDoctypeMappingCsv}
                              me={me}
                            />
                          </ol>
                        </React.Fragment>
                      )}
                    </Box>
                  </Grid>
                  <CheckedOperations />
                  <ElementListPagination
                    urlState={urlState}
                    onSearch={onSearch}
                  />
                  <ElementListRecordCountRow
                    urlState={urlState}
                    setUrlState={setUrlState}
                    onSearch={onSearch}
                  />
                </React.Fragment>
              )}
            </Grid>
          </Container>
        </Box>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={errorModal.open}
        autoHideDuration={6000}
        onClose={closeErrorModalHandler}
      >
        <ErrorSnackbar
          onClose={closeErrorModalHandler}
          variant="error"
          message={errorModal.errorMessage}
        />
      </Snackbar>
    </React.Fragment>
  );
};

export default ElementList;
