import React from 'react';
import { bibSearchResultVar } from '../../../apollo/localState/mainSearch/bibSearchResultState';
// STYLE
import Grid from '@material-ui/core/Grid';
// HOOKS
import { useQuery, useReactiveVar } from '@apollo/client';
// COMPONENTS
import ElementListMosaicViewRecord from './ElementListMosaicViewRecord/ElementListMosaicViewRecord';
import { accessTokenVar } from '../../../apollo/localState/accessTokenVar';
import {
  getResolvedRecordsQuery,
} from '../../../apollo/queries/searchRelatedQueries';
import jwt_decode from 'jwt-decode';
import { CircularProgress } from '@material-ui/core';
import { controlledFeatures } from '../../../utils/featureControl';

const ElementListMosaicViewRecords = ({ allDoctypeMappingCsv }) => {
  const { accessToken } = useReactiveVar(accessTokenVar);
  const bibState = useReactiveVar(bibSearchResultVar);
  const { records } = bibState;

  const { data, loading } = useQuery(
    getResolvedRecordsQuery({fetchItems: controlledFeatures.itemList}),
    {
      variables: {
        where: {
          id_in: records.map(record => record.id),
        },
        ...(accessToken
          ? {
              calculatedItemPolicyForPatronWhere: {
                id: accessToken ? jwt_decode(accessToken).guid : '',
              },
            }
          : null),
        first: records?.length,
        skip: 0,
      },
      skip: records?.length === 0,
    }
  );

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  const mappedRecordsByElasticResult = records.map(record =>
    data?.searchBibliographicRecord?.nodes?.find(x => x.guid === record.id)
  );

  return (
    <Grid container spacing={3} className="resultMosaic">
      {mappedRecordsByElasticResult?.map(record => {
        return (
          <Grid item key={record.id} xs={6}>
            <ElementListMosaicViewRecord
              record={record}
              allDoctypeMappingCsv={allDoctypeMappingCsv}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ElementListMosaicViewRecords;
