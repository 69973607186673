import React from 'react';
// STYLE
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
// COMPONENTS
import ElementListMosaicViewRecordCardContent from './ElementListMosaicViewRecordCardContent/ElementListMosaicViewRecordCardContent';
import DocRefDialog from '../../../DocumentMarcDisplayModal/DocumentReference';

const ElementListMosaicViewRecord = ({ record, allDoctypeMappingCsv }) => {
  return (
    <Card variant="outlined">
      <ElementListMosaicViewRecordCardContent
        record={record}
        allDoctypeMappingCsv={allDoctypeMappingCsv}
      />
      <CardActions>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <DocRefDialog data1={record.rawMarcRecord} />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default ElementListMosaicViewRecord;
