import React from 'react';
import { bibSearchResultVar } from '../../../apollo/localState/mainSearch/bibSearchResultState';
// STYLE
import Grid from '@material-ui/core/Grid';
import searchStyles from '../../../pages/search.module.scss';
// HOOKS
import { useReactiveVar } from '@apollo/client';
// COMPONENTS
import MainSearchBibResultFilterBox from './MainSearchBibResultFilterBox/MainSearchBibResultFilterBox';
// import YearOfPublicationIntervallumBox from './YearOfPublicationIntervallumBox/YearOfPublicationIntervallumBox';

const MainSearchBibResultFilter = ({ onSearch }) => {
  const bibSearchResultState = useReactiveVar(bibSearchResultVar);
  const { aggregations } = bibSearchResultState;

  return (
    <Grid item md={3} className={searchStyles.searchAdvancedElements}>
      <div className={searchStyles.searchAdvancedHeader}>
        <span>Találat pontosítása</span>
      </div>
      {Object.keys(aggregations || {}).map(filterKey => {
        const aggregation = aggregations[filterKey];
        const { isActive, displayName, values } = aggregation;

        if (!values || !values.length || !isActive) {
          return null;
        }

        return (
          <div key={filterKey}>
            <MainSearchBibResultFilterBox
              filterKey={filterKey}
              displayName={displayName}
              values={values}
              onSearch={onSearch}
            />
          </div>
        );
      })}
      {/* <YearOfPublicationIntervallumBox /> */}
    </Grid>
  );
};

export default MainSearchBibResultFilter;
