import React from 'react'
// STYLE
import searchStyles from '../../pages/search.module.scss'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// HOOKS
import { useQueryParams } from 'use-query-params';
import { useBibSearchResultStateSetters } from '../../hooks/useBibSearchResultStateSetters';
// CONSTANTS
import { MAIN_SEARCH_URL_STATE_DEFAULT_VALUE } from '../MainSearch/MainSearch';

const PageSizeSelect = ({ urlState, setUrlState, onSearch }) => {
    const { itemPerPage } = urlState;
    const [_, setDefaultUrlState] = useQueryParams(
        MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
    );
    const { setBibSearchResultStateProperties } = useBibSearchResultStateSetters();

    return (
        <FormControl variant="outlined" className={searchStyles.pagerControl}>
            <Select
                onChange={e => {
                    setBibSearchResultStateProperties({
                        loading: true
                    });
                    const newValue = +e.target.value;
                    const newUrlState = {
                        ...urlState,
                        itemPerPage: newValue
                    }
                    setDefaultUrlState({
                        itemPerPage: newValue,
                    });
                    setUrlState(prevState => {
                        return {
                            ...prevState,
                            itemPerPage: newValue
                        }
                    })
                    onSearch(newUrlState);
                }}
                value={itemPerPage}
            >
                <MenuItem value={10}>10/lap</MenuItem>
                <MenuItem value={20}>20/lap</MenuItem>
                <MenuItem value={50}>50/lap</MenuItem>
                <MenuItem value={100}>100/lap</MenuItem>
            </Select>
        </FormControl>
    )
}

export default PageSizeSelect
