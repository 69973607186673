import React from 'react';
import { bibSearchResultVar } from '../../../apollo/localState/mainSearch/bibSearchResultState';
// STYLE
import searchStyles from '../../../pages/search.module.scss';
import Grid from '@material-ui/core/Grid';
// HOOKS
import { useReactiveVar } from '@apollo/client';
// COMPONENTS
import PageSizeSelect from '../../../components/PageSizeSelect/PageSizeSelect';
import ElementListRecordCountRowViewSelect from './ElementListRecordCountRowViewSelect/ElementListRecordCountRowViewSelect';
import ElementListRecordCountRowSortSelect from './ElementListRecordCountRowSortSelect/ElementListRecordCountRowSortSelect';

const ElementListRecordCountRow = ({ urlState, setUrlState, onSearch }) => {
  const bibResultState = useReactiveVar(bibSearchResultVar);
  const { recordCount } = bibResultState;

  return (
    <Grid
      container
      className={searchStyles.searchstaticDataBlock}
      justifyContent="space-between"
    >
      <Grid item>Találatok: ({recordCount})</Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <ElementListRecordCountRowSortSelect
              urlState={urlState}
              setUrlState={setUrlState}
              onSearch={onSearch}
            />
          </Grid>
          <Grid item>
            <ElementListRecordCountRowViewSelect
              urlState={urlState}
              setUrlState={setUrlState}
            />
          </Grid>
          <Grid item>
            <PageSizeSelect
              urlState={urlState}
              setUrlState={setUrlState}
              onSearch={onSearch}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ElementListRecordCountRow;
