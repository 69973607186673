import React, { useState } from 'react';
import { bibSearchResultVar } from '../../../../apollo/localState/mainSearch/bibSearchResultState';
// STYLE
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// HOOKS
import { useBibSearchResultStateSetters } from '../../../../hooks/useBibSearchResultStateSetters';
import { useReactiveVar } from '@apollo/client';
// UTILS

const MainSearchBibResultFilterBox = ({
  filterKey,
  values,
  displayName,
  onSearch,
}) => {
  const {
    setBibSearchResultStateProperties,
  } = useBibSearchResultStateSetters();
  const bibSearchResultState = useReactiveVar(bibSearchResultVar);
  const { filters } = bibSearchResultState;

  const [showAll, setShowAll] = useState(false);

  const checkboxToggledHandler = key => {
    if (!filters[filterKey]) {
      // MOST PIPÁLTA BE, NINCS MÁSIK BEPIPÁLVA A KATEGÓRIÁBAN
      setBibSearchResultStateProperties({
        filters: {
          ...filters,
          [filterKey]: [key],
        },
        page: 0,
        loading: true,
      });

      onSearch();
      return null;
    }

    if (filters[filterKey].includes(key)) {
      const newFilters = {
        ...filters,
        [filterKey]: filters[filterKey].filter(value => value !== key),
      };

      // MOST PIPÁLTA KI
      setBibSearchResultStateProperties({
        filters: Object.keys(newFilters).reduce((acc, key) => {
          if (newFilters[key].length > 0) {
            return {
              ...acc,
              [key]: newFilters[key],
            };
          }

          return acc;
        }, {}),
        page: 0,
        loading: true,
      });

      onSearch();
      return null;
    }

    //MOST PIPÁLTA BE, MÁSIK IS BE VAN PIPÁLVA A KATEGÓRIÁBAN
    setBibSearchResultStateProperties({
      filters: {
        ...filters,
        [filterKey]: [...filters[filterKey], key],
      },
      page: 0,
      loading: true,
    });
    onSearch();
  };

  const getRows = () => {
    const rows = [];

    for (let i = 0; i < values.length; i++) {
      rows.push(values[i]);

      if (i === 3 && !showAll) {
        break;
      }
    }

    return rows;
  };

  /** @todo nyelv feloldása, majd facetta visszarakása */
  if (displayName === 'Nyelv') {
    return null;
  }

  return (
    <div>
      <Grid container direction="column" spacing={1}>
        <Grid
          item
          style={{ backgroundColor: '#e8b96b', padding: 0, height: 38 }}
        >
          <Typography
            style={{
              color: '#FFFFFF',
              marginLeft: '1rem',
              marginTop: '.4rem',
              fontSize: '1.1rem',
            }}
          >
            {displayName}
          </Typography>
        </Grid>

        <Grid item>
          <Grid container direction="column" className="filterContainer">
            {getRows().map(value => {
              const { key, doc_count } = value;

              return (
                <Grid item key={filterKey + key}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="baseline"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Checkbox
                        checked={!!filters[filterKey]?.includes(key)}
                        onChange={e => checkboxToggledHandler(key)}
                      />
                    </Grid>
                    <Grid item>
                      <Typography color="textSecondary">{key}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>({doc_count})</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {values.length > 4 ? (
          <Grid item>
            <Button
              color="primary"
              size="small"
              onClick={() => setShowAll(prevShowAll => !prevShowAll)}
            >
              {showAll ? 'Kevesebb mutatása' : 'Összes mutatása'}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default MainSearchBibResultFilterBox;
